<template>
  <div class="voucher-detail mt10">
    <a-spin :spinning="loading">
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox
              id="BaseInfo"
              :title="$t('记账凭证')"
              :showCopy="isDetail"
              @copyOrder="copyOrder"
            >
              <template v-slot:header>
                <div class="btns flex-ct" style="margin-left: auto">
                  <OperateBtn
                    v-if="formData.status === 'WAIT_CHECK'"
                    api="checkVoucher"
                    :text="$t('审核')"
                    :params="{
                      idList: [id],
                    }"
                    @success="init"
                  />
                </div>
              </template>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`凭证单据号`)">
                    <a-input disabled v-model="formData.voucherSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`账簿`)" required>
                    <CommonQuerySelect
                      api="getAccountSetList"
                      :disabled="isDisabled"
                      :code.sync="formData.accountSetCode"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`业务日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.bizTime"
                      @choose="chooseBizDate"
                      :disabled="isDisabled"
                      :placehold="$t(`业务日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      @choose="chooseAccountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`期间`)" required>
                    <CommonQuerySelect
                      api="queryAccountingCalendarDetailPage"
                      valueName="accounting_period_code"
                      labelName="accounting_period_name"
                      :code.sync="formData.accountingPeriod"
                      :placeholer="$t(`期间`)"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`凭证字`)" required>
                    <CommonSelect
                      :list="journal_voucher_type"
                      :code.sync="formData.voucherType"
                      :placeholder="$t('凭证字')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input v-model="formData.remark" :disabled="isDisabled" />
                  </a-form-model-item>
                </a-col>
                <template v-if="isDetail">
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`来源单据`)">
                      <a-input
                        :value="documentTypeMapping[formData.sourceDocType]"
                        :disabled="true"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`状态`)">
                      <a-input
                        disabled
                        v-model="journalVoucherStatusMapping[formData.status]"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="24">
                    <a-form-model-item :label="$t(`来源单据单号`)">
                      <template v-if="formData.sourceDocSn?.length > 0">
                        <span
                          class="text-link mr10"
                          v-for="(item, index) in formData.sourceDocSn.split(',')"
                          :key="item"
                          @click="viewSourceDetail(formData, index)"
                        >
                          {{ item }}
                        </span>
                      </template>
                    </a-form-model-item>
                  </a-col>
                </template>
              </a-row>
            </ModalBox>
          </a-form-model>
          <ModalBox id="ProductInfo" :title="$t('明细')">
            <template v-slot:header>
              <div class="option-btns pr20" v-if="!isDisabled">
                <span class="text-link fz16" @click="addLineHandle">
                  {{ $t('增行') }}
                </span>
                <span class="text-link fz16 ml20" @click="deleteLineHandle">
                  {{ $t('删行') }}
                </span>
              </div>
            </template>
            <CommonTable
              ref="CommonTableRef"
              :showSelect="true"
              :selectedRowKeys.sync="selectedRowKeys"
              :columns="columns"
              :dataSource="formData.detailList"
              :showSetting="false"
              :showPagination="false"
              :scroll="{ x: 1500 }"
            >
              <template v-slot:summary="{ record }">
                <span v-if="isDisabled">{{ record.summary }}</span>
                <a-input v-else v-model="record.summary" :disabled="isDisabled" />
              </template>
              <template v-slot:accountCode="{ record }">
                <span v-if="isDisabled" class="text-link" @click="goToLedgerList(record)">
                  {{ record.accountName }}
                </span>
                <CommonQuerySelect
                  v-else
                  :init="false"
                  api="getAccountList"
                  :code.sync="record.accountCode"
                  :name.sync="record.accountName"
                  :disabled="isDisabled"
                  @change="
                    (data) => {
                      changeAccount(data, record)
                    }
                  "
                />
              </template>
              <template v-slot:dimensionList="{ record }">
                <template v-if="isDisabled">
                  <div
                    v-for="(item, index) in record.dimensionList"
                    :key="index"
                    class="text-link"
                    @click="goToLedgerList(item)"
                  >
                    {{ item.accountingDimensionName }}: {{ item.accountingDimensionValueName }}
                  </div>
                </template>
                <a-input
                  v-else-if="record.dimensionList?.length > 0"
                  readOnly
                  class="ant-input ellipsis"
                  :disabled="isDisabled"
                  :value="record.dimensionListName"
                  @click="showDimensionModal(record)"
                />
              </template>
              <template v-slot:currency="{ record }">
                <span v-if="isDisabled">{{ currencyListMapping[record.currency] }}</span>
                <SelectCurrency v-else :code.sync="record.currency" :disabled="isDisabled" />
              </template>
              <template v-slot:debitAmount="{ record }">
                <PriceInput
                  :showZero="record.type === 'summary'"
                  :value.sync="record.debitAmount"
                  :disabled="isDisabled"
                  :showTxt="record.type === 'summary' || isDisabled"
                  :currency="record.currency"
                  @blur="
                    (val) => {
                      blurHandle(val, record, 'creditAmount')
                    }
                  "
                />
              </template>
              <template v-slot:creditAmount="{ record }">
                <PriceInput
                  :showZero="record.type === 'summary'"
                  :value.sync="record.creditAmount"
                  :disabled="isDisabled"
                  :showTxt="record.type === 'summary' || isDisabled"
                  :currency="record.currency"
                  @blur="
                    (val) => {
                      blurHandle(val, record, 'debitAmount')
                    }
                  "
                />
              </template>
            </CommonTable>
          </ModalBox>
        </section>
      </div>
      <BottomBtns
        :canEdit="false"
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <a-modal
      :keyboard="false"
      :maskClosable="false"
      v-model="dimensionVisible"
      :title="$t('核算维度')"
      @cancel="handleDimensionCancel"
      @ok="handleDimensionOk"
    >
      <a-form-model>
        <a-form-model-item
          v-for="(item, index) in dimensionData.dimensionList"
          :key="index"
          :label="item.accountingDimensionName"
        >
          <CommonQuerySelect
            api="getCommonList"
            :code.sync="item.accountingDimensionValueCode"
            :params="{
              businessUnitCode: formData.businessUnitCode,
              tableName: item.accountingDimensionSourceTable,
            }"
            @change="
              (data) => {
                changeSelect(data, item)
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import SelectCurrency from '@component/selectCurrency'
import { convertKeysToCamelCase, goBack, jumpSoucePage } from '@/common'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { add } from '@/common/number'
export default {
  name: 'VoucherDetail',
  components: { DatePicker, BottomBtns, SelectCurrency },
  data() {
    return {
      id: undefined,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        functionalCurrency: undefined,
        detailList: [],
        status: 'DRAFT',
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      selectedRowKeys: [],
      dimensionData: {},
      dimensionVisible: false,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['journal_voucher_type']),
    ...mapGetters(['journalVoucherStatusMapping', 'documentTypeMapping', 'currencyListMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 200 + 'px'
    },
    columns() {
      return [
        {
          title: this.$t('摘要'),
          dataIndex: 'summary',
          scopedSlots: {
            customRender: 'summary',
          },
          width: 350,
        },
        {
          title: this.$t('科目'),
          dataIndex: 'accountCode',
          scopedSlots: {
            customRender: 'accountCode',
          },
          width: 200,
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimensionList',
          scopedSlots: {
            customRender: 'dimensionList',
          },
          width: 250,
        },
        {
          title: this.$t('币别'),
          dataIndex: 'currncy',
          scopedSlots: {
            customRender: 'currency',
          },
          width: 100,
        },
        {
          title: this.$t('借方'),
          dataIndex: 'debitAmount',
          scopedSlots: {
            customRender: 'debitAmount',
          },
          width: 200,
        },
        {
          title: this.$t('贷方'),
          dataIndex: 'creditAmount',
          scopedSlots: {
            customRender: 'creditAmount',
          },
          width: 200,
        },
      ]
    },
  },
  methods: {
    init() {
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.bizTime = moment().format('YYYY-MM-DD')
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        this.formData.accountingPeriod = moment().format('YYYY-MM')
      } else {
        this.id = this.$route.query.id
        this.isDetail = true
      }
      this.getDetailInfo()
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      this.formData.functionalCurrency = value.currency
    },
    getDetailInfo() {
      this.isCopy = false
      let voucherFormdata = localStorage.getItem('voucherFormdata')
      if (this.$route.query.id) {
        this.loading = true
        http({
          url: api.getVoucherDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(deepClone(res.result))
            result.detailList.forEach((item) => {
              this.getShowName(item)
              if (item.direction === 'CREDIT') {
                item.creditAmount = item.amount
              } else {
                item.debitAmount = item.amount
              }
            })
            if (result.status === 'DRAFT') {
              this.isEdit = true
            }
            this.formData = { ...this.formData, ...result }
            this.loading = false
            this.$nextTick(() => {
              this.calTotalLine()
            })
          },
          fail: (err) => {
            this.loading = false
            this.$message.error(err.msg || err)
          },
        })
      } else if (voucherFormdata) {
        this.isCopy = true
        let data = JSON.parse(voucherFormdata)
        this.formData = data
        this.formData.voucherSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        localStorage.removeItem('voucherFormdata')
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    chooseAccountingDate(data) {
      this.formData.accountingDate = data.data
    },
    copyOrder() {
      let data = JSON.stringify(this.formData)
      localStorage.setItem('voucherFormdata', data)
      this.$router.push({ name: 'voucherDetail' })
    },
    backForm(back = 0) {
      goBack('voucherList', back)
    },
    addLineHandle() {
      const data = {
        summary: undefined,
        accountCode: undefined,
        accountName: undefined,
        currency: this.formData.functionalCurrency,
      }
      let summaryIndex = this.formData.detailList.findIndex((item) => item.type === 'summary')
      if (summaryIndex > -1) {
        this.formData.detailList.splice(-1, 0, data)
      } else {
        this.formData.detailList.push(data)
      }
    },
    deleteLineHandle() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.detailList = this.formData.detailList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      this.selectedRowKeys = []
      this.$nextTick(() => {
        this.calTotalLine()
      })
    },
    changeAccount({ id }, record) {
      http({
        url: api.getAccountDetail,
        data: {
          id,
        },
        success: (res) => {
          let list = res.result.dimension_list.map((item) => ({
            accountingDimensionSourceTable: item.source_table,
            accountingDimensionCode: item.value_code,
            accountingDimensionName: item.value_name,
            accountingDimensionValueCode: '',
            accountingDimensionValueName: '',
          }))
          this.$set(record, 'dimensionList', list)
          this.getShowName(record)
        },
      })
    },
    showDimensionModal(record) {
      if (this.isDisabled) return
      this.dimensionVisible = true
      this.dimensionData = record
    },
    handleDimensionCancel() {
      this.dimensionVisible = false
    },
    handleDimensionOk() {
      this.getShowName(this.dimensionData)
      this.dimensionVisible = false
    },
    getShowName(data) {
      let dimensionListName = data.dimensionList
        .map((item) => {
          return `${item.accountingDimensionName}: ${item.accountingDimensionValueName}`
        })
        .join(';')
      this.$set(data, 'dimensionListName', dimensionListName)
    },
    changeSelect(data, item) {
      item.accountingDimensionValueCode = data.code
      item.accountingDimensionValueName = data.name
    },
    blurHandle(val, record, name) {
      if (val) {
        this.$set(record, name, '')
      }
      this.$nextTick(() => {
        this.calTotalLine()
      })
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList = data.detailList.filter((item) => item.type !== 'summary')
      data.detailList.forEach((item) => {
        if (item.creditAmount) {
          item.amount = item.creditAmount
          item.direction = 'CREDIT'
        } else {
          item.amount = item.debitAmount
          item.direction = 'DEBIT'
        }
      })
      await http({
        url: api.crateOrUpdateVoucher,
        data,
        success: (res) => {
          if (res.success) {
            this.$message.success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    viewSourceDetail(record, index) {
      let sourceDocSnList = record.sourceDocSn.split(','),
        sourceDocIdList = record.sourceDocId.split(',')
      jumpSoucePage(sourceDocSnList[index], sourceDocIdList[index])
    },
    goToLedgerList(record) {
      this.$router.push({
        name: 'ledgerList',
        query: {
          accountCode: record.accountCode,
          accountSetCode: this.formData.accountSetCode,
          accountingDimensionCode: record.accountingDimensionCode,
          accountingDimensionValueCode: record.accountingDimensionValueCode,
          accountingDimensionValueName: record.accountingDimensionValueName,
        },
      })
    },
    calTotalLine() {
      this.formData.detailList = this.formData.detailList.filter((item) => item.type !== 'summary')
      if (this.formData.detailList.length === 1) return
      let debitTotalAmount = 0,
        creditTotalAmount = 0
      this.formData.detailList.forEach((item) => {
        debitTotalAmount = add(debitTotalAmount, item.debitAmount || 0)
        creditTotalAmount = add(creditTotalAmount, item.creditAmount || 0)
      })
      this.formData.detailList.push({
        type: 'summary',
        debitAmount: debitTotalAmount,
        creditAmount: creditTotalAmount,
      })
    },
  },
}
</script>
